.features-section__container {
  padding: 50px 0 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.features-section__content {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("grafika_1.jpg");
  background-size: cover;
  background-position: center;
  color: var(--white-color);
  padding: 25px;
  overflow: hidden;
  transform: translateX(10%);
  /* box-shadow: 1px 1px 15px 5px #b1b0b0; */
  z-index: 2;
}

.features-section__slogan-wrapper {
  background-color: white;
  transform: translate(-10%, 15%);
  /* box-shadow: 1px 1px 15px 5px #b1b0b0; */
  color: var(--main-color);
  font-weight: 500;
}

.features-section__slogan {
  font-size: 3.5rem;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 25px 25px 25px 100px;
  letter-spacing: 1px;
  line-height: 170%;
}

.features-section__slogan-link {
  font-size: 3rem;
}

.features-section__page-title {
  text-align: left;
  padding-bottom: 20px;
  font-size: 3rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.features-section__list {
  font-size: 2.4rem;
  font-weight: 300;
  padding: 50px 0 0;
}

.features-section__list-element,
.features-section__list-element.active {
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}

.features-section__list-element {
  list-style: none;
  margin: 35px 0 35px 0;
  padding-left: 10px;
  border-left: 1px solid;
  transform: translateX(-100%);
}

.features-section__list-element.active {
  transform: translateX(0%);
}

.features-section__list-element--even {
  text-align: right;
  padding-right: 10px;
  border-right: 1px solid;
  border-left: none;
  transform: translateX(100%);
}

@media (max-width: 1300px) {
  .features-section__slogan {
    font-size: 3.6rem;
    padding-left: 120px;
  }
}

@media (max-width: 1000px) {
  .features-section__container {
    display: block;
    padding-bottom: 100px;
  }

  .features-section__slogan-wrapper {
    background-color: var(--white-color);
    transform: none;
    box-shadow: none;
  }

  .features-section__slogan {
    text-align: left;
    position: static;
    transform: none;
    padding: 50px;
    line-height: 140%;
  }

  .features-section__content {
    transform: translateX(0%);
    width: 90%;
    margin: 0 auto;
    padding: 70px 50px;
  }

  .features-section__list-element {
    color: var(--white-color);
    border-color: var(--white-color);
    margin: 20px 0;
  }

  .features-section__page-title {
    color: var(--white-color);
  }
}

@media (max-width: 680px) {
  .features-section__list-element {
    font-size: 1.8rem;
  }

  .features-section__page-title {
    font-size: 2.6rem;
  }
}

@media (max-width: 500px) {
  .features-section__content {
    transform: translateX(0%);
    width: 90%;
    margin: 0 auto;
    padding: 50px 20px;
  }

  .features-section__list {
    padding: 20px 0 0;
  }
}
