.eurekaSlogan__container {
  padding: 100px 20px 70px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.eurekaSlogan__content {
  max-width: 1000px;
  margin: 50px auto;
  padding: 30px;
  overflow: hidden;
  display: flex;
  gap: 40px;
  align-items: center;
  
}

.eurekaSlogan__content--right {
 
}

.eurekaSlogan__content--left {
  flex-direction: row-reverse;
}

.eurekaSlogan__page-title {
  text-align: right;
  padding-bottom: 20px;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: #E7CF49;
}

.eurekaSlogan__page-title--right {
  text-align: right;
}

.eurekaSlogan__page-title--left {
  text-align: left;
}

.eurekaSlogan__text {
  font-size: 1.8rem;
  font-weight: 300;
  transition-duration: 0.4s;
  transition-delay: 0.1s;
  max-width: 700px;

}

.eurekaSlogan__text--left {
  text-align: left;
  transform: translateX(-200%);
}

.eurekaSlogan__text--right {
  text-align: right;
  transform: translateX(200%);
}

.eurekaSlogan__text.active {
  transform: translateX(0%);
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}
@media (max-width: 800px) {
.eurekaSlogan__content {
flex-wrap: wrap;
}
}
@media (max-width: 400px) {
  .eurekaSlogan__text {
    font-size: 1.6rem;
  }

  .eurekaSlogan__content {
    padding: 15px;
  }

  .eurekaSlogan__container {
    padding: 50px 15px;
  }
}
