.hero-section__container {
    height: 100vh;
    width: 100%;
    background-image: url("mario-gogh-VBLHICVh-lI-unsplash.jpg");
    background-size: cover;
    background-position: center;
}

.hero-section__button-container {
    max-width: var(--container-max-width);
    margin: 0 auto;
    text-align: center;
    position: relative;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    padding: 0 10px;
    display: flex;
    justify-content: center;
}

.hero-section__slogan {
    font-size: 6rem;
    font-weight: 600;
    letter-spacing: 1.4px;
    color: var(--white-color);
    padding-bottom: 30px;
    max-width: 800px;
}

.hero-section__arrow-icon {
    font-size: 1.6rem;
    margin-left: 5px;
    transition: .2s;
}

.hero-section__button-container:hover .hero-section__arrow-icon{
    transform: translateX(30%);
    
}


@media (max-width: 680px) {
    .hero-section__slogan {
        font-size: 5rem;
    }
}

@media (max-width: 450px) {
    .hero-section__arrow-icon {
        display: none;
    }

    .hero-section__slogan {
        font-size: 3.8rem;
    }
}

