.eurekaSlogan__container {
  padding: 0px 20px 20px 20px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.eurekaSlogan__slogan-wrapper {
  background-color:  #025072;
  margin-top: 50px;
  padding: 70px 30px;
  /* box-shadow: 1px 1px 15px 5px #b1b0b0; */
}

.eurekaSlogan__slogan {
  font-size: 3.7rem;
  color: var(--white-color);
  text-align: center;
  font-weight: 500;
}

@media (max-width: 500px) {
  .eurekaSlogan__slogan-wrapper {
    padding: 50px 20px;
  }
  .eurekaSlogan__slogan {
    font-size: 3rem;
  }
}

@media (max-width: 400px) {
  .eurekaSlogan__slogan {
    font-size: 2.8rem;
  }
  .eurekaSlogan__container {
    padding: 50px 15px;
  }
}
