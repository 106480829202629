.about-section__container {
    padding: 100px 20px 70px;
    max-width: var(--container-max-width);
    margin: 0 auto;
}

.about-section__slogan-wrapper {
    background-color: #183962;
    margin-top: 80px;
    padding: 70px 30px;
    /* box-shadow: 1px 1px 15px 5px #b1b0b0; */
}

.about-section__slogan {
    font-size: 4rem;
    color: var(--white-color);
    text-align: center;
    font-weight: 500;
}

.about-section__content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
    overflow: hidden;
    display: flex;
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
}

.about-section__page-title {
    text-align: right;
    padding-bottom: 20px;
    font-size: 4.5rem;
    letter-spacing: 2px;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--main-color)
}

.about-section__text {
    text-align: left;
    font-size: 1.8rem;
    font-weight: 300;
    transform: translateX(200%);
    transition-duration: .4s;
    transition-delay: .1s;
    max-width: 600px;
}

.about-section__text.active {
    transform: translateX(0%);
    transition-duration: .4s;
    transition-delay: .1s;
}

@media (max-width: 500px) {
    .about-section__slogan-wrapper {
        padding: 50px 20px;
    }
    .about-section__slogan {
        font-size: 3rem;
    }
}

@media (max-width: 400px) {
    .about-section__slogan {
        font-size: 2.8rem;
    }

    .about-section__text {
        font-size: 1.6rem;
    }

    .about-section__content {
        padding: 15px;
    }

    .about-section__container {
        padding: 50px 15px;
    }
}