.contact-section__container {
  padding: 100px;
  background-color: white;
  /* border-top: 1px solid #b1b0b0; */
  /* box-shadow: 1px 1px 15px 5px #b1b0b0; */
}

.contact-section__page-title {
  text-align: center;
  font-size: 3.6rem;
  font-weight: 500;
  letter-spacing: 0.7px;
  padding-bottom: 30px;
  color: #81AA29;
}

.contact-section__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.contact-section__form-inner-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-bottom: 20px;
}

.contact-section__input-wrapper {
  display: flex;
  flex-direction: column;
}

.contact-section__input,
.contact-section__textarea {
  width: 350px;
  padding: 5px;
  margin: 20px 0;
  font-size: 1.6rem;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--main-color);
  border-bottom: 1px solid #81AA29;
  font-family: "Poppins", sans-serif;
}

.contact-section__textarea {
  resize: none;
}

@media (max-width: 900px) {
  .contact-section__input,
  .contact-section__textarea {
    width: 100%;
  }

  .contact-section__form-inner-wrapper {
    width: 100%;
  }
  .contact-section__container {
    padding: 50px;
  }
}

@media (max-width: 650px) {
  .contact-section__form-inner-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }

  .contact-section__textarea {
    height: 250px;
  }
}

@media (max-width: 500px) {
  .contact-section__container {
    padding: 50px 20px;
  }
}
